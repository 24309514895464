.App {
  @apply bg-slate-50;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-img {
  background-image: linear-gradient(
      rgba(112, 112, 112, 0.9),
      rgba(160, 52, 115, 0.8)
    ),
    url(/public/images/laptop2.jpeg);
}

/* Card Zoom Styles from: https://daily-dev-tips.com/posts/tailwind-zooming-background-images/ */
.card-zoom {
  @apply relative flex items-end justify-start m-3 overflow-hidden shadow-xl w-full md:w-80 h-80 rounded-2xl border-t;
}

.card-zoom-image {
  @apply absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-pink-400;
}

.card-image-overlay {
  @apply absolute w-full h-full;
}

.card-zoom-text {
  @apply absolute flex flex-col gap-1 p-6 font-black transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-0;
}

.card-zoom:hover .card-image-overlay {
  @apply scale-150 bg-gradient-to-t from-blue-500 to-pink-400 opacity-90;
}

.card-zoom:hover .card-zoom-image {
  @apply scale-150;
}

.card-zoom:hover .card-zoom-text {
  @apply scale-100 opacity-100;
}

#about,
#projects {
  scroll-margin-top: 5em;
}
